import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  Anchor,
  Box,
  Button,
  Code,
  Flex,
  List,
  Loader,
  ScrollArea,
  Select,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { CreationForm } from "../../../components/form/CreationForm";
import * as yup from "yup";
import { notifications } from "@mantine/notifications";
import { graphql } from "gql.tada";
import { PlatformFileQuery } from "./PlatformFiles";
import { findKey, forEach, isArray, map } from "lodash";
import { modals } from "@mantine/modals";
import { useEffect, useRef, useState } from "react";
import { Prism } from "@mantine/prism";
import { IconInfo } from "@aws-amplify/ui-react/internal";
import { PAGE_TEMPLATES } from "./page-templates";
import { useSelectedOrganizationId } from "../../../../../business/OrganizationId";
import { useDebouncedValue, useInterval } from "@mantine/hooks";
import { TagDefinitions, Variable } from "./available-tags";

const PlatformFileUpdateMutation = graphql(`
  mutation platformFileUpdateData($id: ID!, $input: PlatformFileUpdateInput!) {
    platformFileUpdate(id: $id, input: $input) {
      id
    }
  }
`);

const PlatformFilePublishMutation = graphql(`
  mutation platformFilePublish($id: ID!) {
    platformFilePublish(id: $id)
  }
`);

const uploadFileToS3 = async (
  { url, fields }: { url: string; fields: { key: string; value: string }[] },
  contents: Blob,
) => {
  const formData = new FormData();
  formData.append("Content-Type", "text/html");
  forEach(fields, ({ key, value }) => {
    formData.append(key, value);
  });
  formData.append("file", contents);
  return fetch(url, {
    method: "POST",
    body: formData,
  });
};

const fileExtensionToLanguageMap: Record<string, string> = {
  js: "javascript",
  ts: "typescript",
  json: "json",
  html: "html",
  css: "css",
  scss: "scss",
  liquid: "liquid",
  md: "markdown",
  yml: "yaml",
  yaml: "yaml",
};

export const PlatformFileContent = () => {
  const organizationId = useSelectedOrganizationId();
  const fileId = useParams().fileId as string;
  const [publishing, setPublishing] = useState(false);
  const [fileUpdated, setFileUpdated] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string>();
  const [contents, setContents] = useState<string>("");
  const [debouncedContents] = useDebouncedValue(contents, 500, {
    leading: true,
  });
  const [hotUpdatesWindow, setHotUpdatesWindow] = useState<
    | {
        window: Window;
        ready: boolean;
        messageHandler: (message: MessageEvent) => void;
      }
    | undefined
  >();
  const hotUpdatesWindowRef = useRef<{
    window: Window;
    ready: boolean;
    messageHandler: (event: MessageEvent) => void;
  } | null>(null);
  const [publishFile] = useMutation(PlatformFilePublishMutation);
  const { data, loading } = useQuery(PlatformFileQuery, {
    variables: {
      id: fileId,
    },
  });
  const [fileUpdate] = useMutation(PlatformFileUpdateMutation);

  // Update the ref whenever the state changes
  const updateHotUpdatesWindow = (
    newWindow:
      | {
          window: Window;
          ready: boolean;
          messageHandler: (message: MessageEvent) => void;
        }
      | undefined,
  ) => {
    setHotUpdatesWindow(newWindow);
    hotUpdatesWindowRef.current = newWindow ?? null;
  };

  useEffect(() => {
    return () => {
      if (hotUpdatesWindow) {
        window.removeEventListener("message", hotUpdatesWindow.messageHandler);
      }
      childWindowCheckInterval.stop();
    };
  }, []);

  useEffect(() => {
    hotUpdatesWindowRef.current?.window.postMessage(
      {
        type: "updateContents",
        organizationId,
        contents: debouncedContents,
      },
      "*",
    );
  }, [debouncedContents]);

  const childWindowCheckInterval = useInterval(() => {
    if (hotUpdatesWindowRef.current?.window.closed) {
      console.log("Child window has been closed");
      childWindowCheckInterval.stop();
      window.removeEventListener(
        "message",
        hotUpdatesWindowRef.current.messageHandler,
      );
      updateHotUpdatesWindow(undefined);
    }
  }, 500);

  if (loading) {
    return <Loader />;
  }

  const fileExtension = data?.platformFile?.name.split(".")[1];
  const fileLanguage = fileExtension
    ? fileExtensionToLanguageMap[fileExtension]
    : undefined;
  return (
    <>
      {data?.platformFile?.type === "PAGE" && (
        <Select
          label={"Select starter template"}
          data={PAGE_TEMPLATES.map((template) => ({
            label: template.name,
            value: template.template,
          }))}
          onChange={(value) => {
            setSelectedTemplate(value ?? undefined);
            setContents(value ?? "");
          }}
        />
      )}
      <Flex>
        <Box
          key={selectedTemplate}
          style={{
            flex: "0 0 80%",
          }}
        >
          <CreationForm
            title={"Update file contents"}
            schemaDefinition={{
              template: {
                type: "CODE_EDITOR",
                label: "File contents",
                language:
                  data?.platformFile?.type === "PAGE" ||
                  data?.platformFile?.type === "COMPONENT"
                    ? "liquid"
                    : fileLanguage,
                yupConfig: yup.string().required(),
                defaultValue: selectedTemplate ?? data?.platformFile?.contents,
                onChange(contents) {
                  setContents(contents);
                },
              },
            }}
            onSubmit={async (values) => {
              // Convert textarea content to a Blob
              const fileBlob = new Blob([values.template], {
                type: "text/plain",
              });

              await fileUpdate({
                variables: {
                  id: fileId,
                  input: {
                    contents: values.template,
                    config: {
                      pageConfig: data?.platformFile.config?.pageConfig && {
                        path: data?.platformFile.config?.pageConfig?.path,
                      },
                    },
                  },
                },
              }).then((data) => {
                if (data?.errors && data.errors.length > 0) {
                  throw new Error(data.errors[0].message);
                }

                return data;
              });

              setFileUpdated(true);
              notifications.show({
                title: "File updated",
                message: "The file has been updated successfully",
                color: "green",
                autoClose: 3000,
              });
            }}
          />
        </Box>
        <ScrollArea
          style={{
            width: "20%",
            height: "90vh",
            flex: "0 0 20%",
          }}
        >
          <Stack>
            <Button
              w={200}
              color={"green"}
              onClick={async () => {
                setPublishing(true);
                try {
                  await publishFile({ variables: { id: fileId } }).then(
                    (data) => {
                      if (data?.errors && data.errors.length > 0) {
                        throw new Error(data.errors[0].message);
                      }
                    },
                  );
                } catch (e) {
                  notifications.show({
                    title: "Cannot publish file",
                    message: (e as any).message,
                    color: "red",
                    autoClose: 3000,
                  });
                  return;
                } finally {
                  setPublishing(false);
                }
                setFileUpdated(false);
                notifications.show({
                  title: "File published",
                  message: "The file has been published successfully",
                  color: "green",
                  autoClose: 3000,
                });
              }}
              loading={publishing}
              disabled={!fileUpdated}
            >
              Publish file to CDN
            </Button>
            <Button
              w={200}
              color={"blue"}
              onClick={() => {
                if (hotUpdatesWindowRef.current) {
                  hotUpdatesWindowRef.current.window.focus();
                  return;
                }

                const newWindow = window.open(
                  `${window.location.origin
                    .replace("console", "platform")
                    .replace("5173", "5174")}?hotUpdates`,
                  "_blank",
                  "width=800,height=600",
                );

                if (newWindow) {
                  const handleMessage = (event: MessageEvent) => {
                    if (
                      ![
                        "https://platform.well-played.gg",
                        "https://platform.stg.well-played.gg",
                      ].includes(event.origin) &&
                      !event.origin.startsWith("http://localhost")
                    ) {
                      /*                      console.warn(
                        "Ignoring message from unknown origin",
                        event.origin,
                      );*/
                      return;
                    }

                    if (event.data.type === "ready") {
                      console.log("received message:", event);
                      updateHotUpdatesWindow({
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        ...hotUpdatesWindowRef.current!,
                        ready: true,
                      });

                      newWindow.postMessage(
                        {
                          type: "updateContents",
                          organizationId,
                          contents: contents || data?.platformFile?.contents,
                        },
                        "*",
                      );
                    }
                  };

                  updateHotUpdatesWindow({
                    window: newWindow,
                    messageHandler: handleMessage,
                    ready: false,
                  });
                  childWindowCheckInterval.start();

                  window.addEventListener("message", handleMessage);
                } else {
                  modals.open({
                    title: "Error",
                    children: (
                      <>
                        Failed to open new window. Pop-up blockers might be
                        enabled
                      </>
                    ),
                  });
                }
              }}
            >
              Open debug window
            </Button>
            {
              /*(data?.platformFile?.type === "PAGE" ||
              data?.platformFile?.type === "COMPONENT") && */ <>
                <Title order={2}>Introduction to Liquid</Title>
                <Text>
                  Liquid is a simple, expressive and safe Shopify / Github Pages
                  compatible template engine.
                  <br />
                  Liquid syntax is relatively simple. There’re 2 types of
                  markups in Liquid:
                  <br />
                  <Text span fw={700}>
                    Tags.
                  </Text>{" "}
                  A tag consists of a tag name and optional arguments wrapped
                  between {`{ % and %}`}.<br />
                  <Text span fw={700}>
                    Outputs.
                  </Text>{" "}
                  An output consists of a value and a list of filters, which is
                  optional, wrapped between {`{{ and }}`}.
                  <Title order={4} mt={10}>
                    Outputs
                  </Title>
                  Outputs are used to output variables, which can be transformed
                  by filters, into HTML.
                  <br />
                  The following template will insert the value of username into
                  the input’s value:
                  <Prism language={"html" as any}>
                    {`<input type="text" name="user" value="{{username}}">`}
                  </Prism>
                  Values in output can be transformed by filters before output.
                  <br />
                  To append a string after the variable:
                  <Prism language={"html" as any}>
                    {`{{ username | append: ", welcome to Liquid!" }}`}
                  </Prism>
                  Filters can be chained:
                  <Prism language={"html" as any}>
                    {`{{ username | append: ", welcome to Liquid!" | capitalize }}`}
                  </Prism>
                  <Alert
                    variant="light"
                    color="blue"
                    title="Info"
                    icon={<IconInfo />}
                  >
                    The following outputs are already available and can be used
                    in your templates:
                    <br />
                    <List>
                      <List.Item>
                        <Code>params</Code>: the URL parameters defined using{" "}
                        <Code>:myParam</Code> format
                        <br />
                        Usage example: <Code>{`{{ params.myParam }}`}</Code>
                      </List.Item>
                    </List>
                  </Alert>
                  <Anchor
                    target="_blank"
                    href="https://liquidjs.com/filters/overview.html"
                  >
                    Default filters can be found here
                  </Anchor>
                  <Title order={4} mt={10}>
                    Tags
                  </Title>
                  Tags are used to control the template rendering process,
                  manipulating template variables, inter-op with other
                  templates, etc.
                  <br />
                  For example assign can be used to define a variable which can
                  be later used in the template:
                  <Prism
                    language={"html" as any}
                  >{`{% assign foo = "FOO" %}`}</Prism>
                  Typically tags appear in pairs with a start tag and a
                  corresponding end tag. For example:
                  <Prism language={"html" as any}>
                    {`{% if foo == "FOO" %}
Variable "foo" equals "FOO"
{% else %}
Variable "foo" not equals "FOO"
{% endif %}`}
                  </Prism>
                  <Anchor
                    target="_blank"
                    href="https://liquidjs.com/tags/overview.html"
                  >
                    Default tags can be found here
                  </Anchor>
                </Text>
                <Title>Url Parameters</Title>
                If you defined a route with parameters (example:
                /tournaments/:tournamentId), you can access these parameters in
                your templates using the params object.
                <br />
                Usage example:
                <Prism language={"html" as any}>
                  {`{% if params.tournamentId %}
    Tournament ID: {{ params.tournamentId }}
{% endif %}`}
                </Prism>
                <Title>Tags</Title>
                Usage example:
                <br />
                <Prism language={"html" as any}>
                  {`{% favicon "favicon.svg" %}
{% title "Home" %}

<ul>
  {% for item in array %}
    <li>{{ item.name }}</li>
  {% endfor %}
</ul>
`}
                </Prism>
                <Stack>
                  {TagDefinitions.map((tag, idx) => (
                    <Stack spacing={5} key={idx}>
                      <Stack spacing={0}>
                        <Title order={4}>{tag.tag}</Title>
                        {tag.description}
                        <Text fw={700}>Example:</Text>
                        <Prism language={"html" as any}>
                          {`{% ${tag.tag} ${
                            (tag.paramValue &&
                              `"param-value"${
                                (tag.hashInputs && " ") ?? ""
                              }`) ??
                            ""
                          }${map(
                            tag.hashInputs,
                            (value) => `${value.name}:"value"`,
                          ).join(" ")} %}${
                            tag.scopeOutputs
                              ? `\n  ${map(
                                  tag.scopeOutputs,
                                  (schema, key) =>
                                    `{{ ${key}${
                                      (isArray(schema?.type) &&
                                        `.${
                                          findKey(
                                            schema.type,
                                            (value) =>
                                              (value as any).type ===
                                                "String" ||
                                              (value as any).type === "String!",
                                          ) ?? findKey(schema.type, () => true)
                                        }`) ||
                                      ""
                                    } }}`,
                                ).join("\n  ")}\n{% end-${tag.tag} %}`
                              : ``
                          }`}
                        </Prism>
                      </Stack>
                      <Stack spacing={0}>
                        {tag.paramValue && (
                          <>
                            <Text fw={700}>Param value:</Text>

                            <Text key={idx}>
                              <Text fw={700} span>
                                {tag.paramValue.description}
                                {tag.paramValue.required && "*"}
                              </Text>
                            </Text>
                          </>
                        )}
                        {tag.hashInputs && (
                          <>
                            <Text fw={700}>Hash inputs:</Text>
                            {map(tag.hashInputs, (value, idx) => (
                              <Text key={idx}>
                                <Text fw={700} span>
                                  {value.name} -
                                </Text>{" "}
                                {value.type}:{" "}
                                {
                                  (value as unknown as { description: string })
                                    .description
                                }
                              </Text>
                            ))}
                          </>
                        )}
                        {tag.scopeOutputs && (
                          <>
                            <Text fw={700}>Scope outputs:</Text>
                            <Stack spacing={0}>
                              {map(tag.scopeOutputs, (schema, key) => (
                                <Text key={key}>
                                  <Text fw={700} span>
                                    {key}
                                  </Text>
                                  {" - "}
                                  {schema.typeName}
                                  {": "}
                                  {schema.description}
                                </Text>
                              ))}
                            </Stack>
                          </>
                        )}
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
                <Title>Forms</Title>
                Forms allow you to mutate your data on the platform. They can be
                used as a way to create, update or delete data.
                <br />
                They are mainly used to provide a way to call mutations on the
                API.
                <br />
                By using forms, you can ask for user inputs, use hidden inputs
                or simply create a button to register a team to a tournament.
                <br />
                Usage example:
                <br />
                <Prism language={"html" as any}>
                  {`
{% # This variable will be set if the form is submitted %}
{% if formRegisterdTeam %}
    {% if formRegisterdTeam.errors %}
        <p>Errors: {{ formRegisterdTeam.errors }}</p>
    {% else %}
        <p>Team registered successfully</p>
    {% endif %}
{% else %}
    {% capture mutation %}
    mutation registerTournamentTeam(
        $teamName: String!
        $teamTag: String!
        $customFields: [PropertyValueInput!]!
    ) {
      registerTournamentTeam(
        tournamentId: {{ params.tournamentId }}
        input: {name: $teamName, tag: $teamTag, customFields: $customFields}
      ) {
        id
      }
    }
    {% endcapture %}
    {% form mutation:mutation output-variable:"formRegisterdTeam" %}
      <input type="text" name="teamName" placeholder="Team name" />
      <input type="text" name="teamTag" placeholder="Team tag" />
      <input type="number"  name="custom-field-age" pattern="[0-9]*" placeholder="Your age">
      <input type="hidden" name="customFields[0].property" value="hello" />
      <input type="hidden" name="customFields[0].value" value="world" />
      <button type="submit">Submit</button>
    {% endform %}
{% endif %}`}
                </Prism>
              </>
            }
          </Stack>
        </ScrollArea>
      </Flex>
    </>
  );
};
