import { useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Player } from "../../../../gql/graphql";
import {
  Anchor,
  Divider,
  Loader,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";
import { graphql } from "gql.tada";
import { useSelectedOrganizationId } from "../../../../business/OrganizationId";
import { useState } from "react";
import { CustomFieldsWithEditableValue } from "../../components/form/CustomFieldsWithEditableValue";

export const OrganizationQuery = graphql(`
  query organizationGet($id: ID!) {
    organization(id: $id) {
      id
      name
      configuration {
        customFields {
          name
          type
          order
          property
          unique
          required
          editability
          visibility
        }
      }
    }
  }
`);

const UpdatePlayerMutation = graphql(`
  mutation updatePlayer($id: ID!, $input: UpdatePlayerInput!) {
    updatePlayer(id: $id, input: $input) {
      id
    }
  }
`);

const PlayerSkillRatingsQuery = graphql(`
  query playerSkillRatings($playerId: ID!) {
    playerSkillRatings(playerId: $playerId) {
      ratingId
      value
    }

    organizationSkillRatings {
      id
      name
    }
  }
`);

export const PlayerView = () => {
  const playerId = useParams().playerId as string;
  const organizationId = useSelectedOrganizationId();
  const [editLoading, setEditLoading] = useState(false);
  const { data: organization, loading: orgLoading } = useQuery(
    OrganizationQuery,
    {
      variables: {
        id: organizationId ?? "",
      },
    },
  );
  const { data, loading } = useQuery<{ player: Player }>(
    gql`
      query ($id: ID!, $properties: [String!]!) {
        player(id: $id) {
          id
          username
          customFields(properties: $properties) {
            property
            value
          }
          identities {
            organizationIdentityProvider {
              name
            }
            properties {
              property
              value
            }
          }
        }
      }
    `,
    {
      variables: {
        id: playerId,
        properties:
          organization?.organization?.configuration?.customFields?.map(
            (f) => f.property,
          ),
      },
      skip: orgLoading,
    },
  );
  const { data: skillRatingsData, loading: skillRatingsLoading } = useQuery(
    PlayerSkillRatingsQuery,
    {
      variables: {
        playerId,
      },
    },
  );
  const [updatePlayer] = useMutation(UpdatePlayerMutation);

  if (loading || orgLoading) {
    return <Loader />;
  }

  return (
    <>
      <Title>{data?.player.username}</Title>
      <Divider />
      <Title order={2}>Custom fields</Title>
      <Divider />
      {!organization?.organization?.configuration?.customFields?.length && (
        <div>No custom fields</div>
      )}
      <SimpleGrid cols={2} spacing={0}>
        <CustomFieldsWithEditableValue
          fields={organization?.organization?.configuration?.customFields ?? []}
          loading={editLoading}
          defaultValues={data?.player.customFields?.reduce(
            (acc, field) => {
              acc[field.property] = field.value;
              return acc;
            },
            {} as Record<string, string>,
          )}
          onSubmit={async (property, value) => {
            setEditLoading(true);
            await updatePlayer({
              variables: {
                id: playerId,
                input: {
                  customFields: [
                    {
                      property,
                      value,
                    },
                  ],
                },
              },
            })
              .then((data) => {
                if (data?.errors && data.errors.length > 0) {
                  throw new Error(data.errors[0].message);
                }
              })
              .finally(() => {
                setEditLoading(false);
              });
          }}
        />
      </SimpleGrid>
      <Divider />

      <Title order={2}>Identities</Title>
      {!data?.player.identities?.length && <div>No identities</div>}
      {data?.player.identities?.map((identity, idx) => (
        <div key={idx}>
          <Title order={3}>{identity.organizationIdentityProvider?.name}</Title>
          {identity.properties.map((property, idx) => (
            <div key={idx}>
              {property.property}:{" "}
              {property.value.startsWith("http://") ||
              property.value.startsWith("https://") ? (
                <Anchor target="_blank" href={property.value}>
                  {property.value}
                </Anchor>
              ) : (
                property.value
              )}
            </div>
          ))}
        </div>
      ))}
      <Divider />
      <Title order={2}>Skill ratings</Title>
      {skillRatingsLoading && <Loader />}
      <SimpleGrid cols={2} spacing={0}>
        {skillRatingsData?.organizationSkillRatings?.length === 0 && (
          <Text>No skill ratings configured for this organization</Text>
        )}
        {skillRatingsData?.organizationSkillRatings?.map((rating) => {
          const playerRating = skillRatingsData?.playerSkillRatings?.find(
            (r) => r.ratingId === rating.id,
          );
          return (
            <Text key={rating.id}>
              {rating.name} ({rating.id}):{" "}
              {playerRating ? playerRating.value : "No rating"}
            </Text>
          );
        })}
      </SimpleGrid>
    </>
  );
};
