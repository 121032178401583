export type Variable = {
  path: string;
  type: string | Variable | Variable[];
  typeName: string;
  name: string;
  description: string;
  nullable: boolean;
  deprecationReason?: string;
  array: boolean;
};

export interface HashInput extends Omit<Variable, "path" | "type"> {
  hashKey: string;
  type: string;
}

export interface TagInfo {
  tag: string;
  description: string;
  scopeOutputs?: Record<string, Omit<Variable, "name">>;
  hashInputs?: HashInput[];
  paramValue?: {
    description: string;
    required: boolean;
  };
  deprecationReason?: string;
}

export const TagDefinitions: TagInfo[] = [
  {
    tag: "favicon",
    description: "Change the favicon of the page",
    paramValue: {
      description: "The URL of the new favicon",
      required: true,
    },
  },
  {
    tag: "title",
    description: "Set the title of the page",
    paramValue: {
      description: "The new title of the page",
      required: true,
    },
  },
  {
    tag: "stylesheet",
    description: "Injects a stylesheet into the head of the document",
    paramValue: {
      required: true,
      description: "The URL of the stylesheet to inject",
    },
  },
  {
    tag: "nav-link",
    description: "Create a link to a page",
    hashInputs: [
      {
        type: "string",
        name: "to",
        typeName: "string",
        array: false,
        nullable: false,
        hashKey: "to",
        description: "The path to the page",
      },
    ],
  },
  {
    tag: "navigate",
    description: "Navigates to another page",
    paramValue: {
      description: "The path to navigate to",
      required: true,
    },
  },
  {
    tag: "javascript",
    description:
      "Injects javascript into the page. This is useful for adding interactivity to your page.",
  },
  {
    tag: "tournament-step",
    description: "Fetches the groups for a tournament step",
    scopeOutputs: {
      result: {
        type: "object",
        typeName: "object",
        array: true,
        nullable: false,
        description: "The groups for the step",
        path: "",
      },
    },
    hashInputs: [
      {
        type: "string",
        name: "tournamentId",
        typeName: "string",
        array: false,
        nullable: false,
        hashKey: "tournament-id",
        description: "The id of the tournament",
      },
      {
        type: "string",
        name: "stepId",
        typeName: "string",
        array: false,
        nullable: false,
        hashKey: "step-id",
        description: "The id of the step",
      },
    ],
  },
  {
    tag: "tournament-teams",
    description: "Fetches the teams for a tournament",
    scopeOutputs: {
      result: {
        description: "A list of teams for the tournament",
        type: "object",
        typeName: "object",
        array: true,
        path: "",
        nullable: false,
      },
    },
    hashInputs: [
      {
        type: "string",
        name: "tournamentId",
        typeName: "string",
        array: false,
        nullable: false,
        hashKey: "tournament-id",
        description: "The id of the tournament",
      },
      {
        type: "string",
        name: "status",
        typeName: "string",
        array: false,
        nullable: true,
        hashKey: "status",
        description:
          'OPTIONAL - The status of the teams to fetch, can be one of: "AWAITING_FOR_PAYMENT" | "AWAITING_FOR_PRESENCE_CONFIRMATION" | "CONFIRMED" | "DENIED" | "NOT_ATTENDING" | "NOT_VALID" | "REGISTERED"',
      },
    ],
  },
  {
    tag: "rich-text",
    description: "Render rich text content",
    hashInputs: [
      {
        type: "string",
        name: "content",
        typeName: "string",
        array: false,
        nullable: false,
        hashKey: "content",
        description: "The rich text content to render",
      },
    ],
  },
  {
    tag: "form",
    description:
      "Renders a dynamic form to make API mutation calls, input names are used as variables for the mutation, using json Path. Meaning that a name 'user.name' will be used as { user: { name: value } }",
    hashInputs: [
      {
        name: "mutation",
        description: "The graphql mutation to call",
        array: false,
        type: "string",
        nullable: false,
        typeName: "string",
        hashKey: "mutation",
      },
      {
        name: "output-variable",
        description:
          "The global variable to store the result of the API call, will contain `data` and `errors`",
        array: false,
        type: "string",
        nullable: false,
        typeName: "string",
        hashKey: "output-variable",
      },
    ],
  },
  {
    tag: "api-query",
    description: "Call a API Query manually",
    scopeOutputs: {
      result: {
        description: "The result of the API Call",
        type: "any",
        typeName: "any",
        array: false,
        path: "",
        nullable: true,
      },
      error: {
        typeName: "string",
        type: "string",
        description: "Error message",
        nullable: true,
        path: "",
        array: false,
      },
    },
    hashInputs: [
      {
        name: "query",
        description: "The graphql query to call",
        array: false,
        type: "string",
        nullable: false,
        typeName: "string",
        hashKey: "query",
      },
      {
        name: "variables",
        description: "The variables to send with your query, as a json object",
        array: false,
        type: "object",
        nullable: true,
        typeName: "object",
        hashKey: "variables",
      },
    ],
  },
];
