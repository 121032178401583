import {
  Alert,
  Anchor,
  Badge,
  Box,
  Button,
  Card,
  Center,
  Collapse,
  Flex,
  Group,
  Loader,
  RingProgress,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { gql, useQuery } from "@apollo/client";
import { AbbrevitatedNumber } from "../components/AbbrevitatedNumber";
import { OrganizationLimits } from "../../../gql/graphql";
import { ServiceLinks } from "../ServiceLinks";
import { Link } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { Prism } from "@mantine/prism";
import { IconInfoCircle } from "@tabler/icons-react";

export const Dashboard = () => {
  const [opened, { toggle }] = useDisclosure(false);
  const { data, loading } = useQuery<{
    organizationLimits: OrganizationLimits;
  }>(gql`
    query {
      organizationLimits(limits: [REGISTERED_USERS]) {
        limits {
          id
          current
          max
        }
      }
    }
  `);

  if (loading) return <Loader />;

  return (
    <Stack>
      <Stack align="center" spacing={0} ta="center">
        <Title>WellPlayed Console</Title>
        <Text>
          Welcome to the WellPlayed Console. From here you can manage your
          organization, create and manage tournaments, and more
          <br />
          Click on the service you are interested in to get started
        </Text>
        <Text fs={"italic"}>
          <Anchor
            href="https://api.warrior.well-played.gg/graphql"
            target="_blank"
            rel="noopener noreferrer"
          >
            Play with the API now!
          </Anchor>
        </Text>
        <Group spacing={5}>
          <Badge color="violet" variant="filled">
            NEW
          </Badge>
          <Anchor
            fs={"italic"}
            href="https://www.npmjs.com/package/@well-played.gg/react-sdk"
            target="_blank"
          >
            React SDK is now available!
          </Anchor>
        </Group>
      </Stack>
      <Flex wrap="wrap" gap="md">
        <Card withBorder={true} w={200}>
          <Card.Section>
            <Center>
              <RingProgress
                sections={[
                  {
                    value:
                      (data?.organizationLimits?.limits[0]?.current ?? 0) /
                      (data?.organizationLimits?.limits[0]?.max ?? 1),

                    color: "blue",
                  },
                ]}
                size={100}
                label={
                  <Center>
                    <AbbrevitatedNumber
                      value={data?.organizationLimits?.limits[0]?.current ?? 0}
                    />
                  </Center>
                }
              />
            </Center>
          </Card.Section>
          <Center>
            <Stack align="center">
              <Text weight={700}>Users</Text>
              <Text>
                <AbbrevitatedNumber
                  value={data?.organizationLimits?.limits[0]?.current ?? 0}
                />{" "}
                /{" "}
                <AbbrevitatedNumber
                  value={data?.organizationLimits?.limits[0]?.max ?? 0}
                />
              </Text>
            </Stack>
          </Center>
        </Card>
        {ServiceLinks.find(
          (service) => service.title === "Services",
        )?.childrens?.map((service, idx) => (
          <Card
            style={{
              ...(service.disabled
                ? {
                    pointerEvents: "none",
                    opacity: 0.5,
                  }
                : {}),
            }}
            ta={"center"}
            withBorder={true}
            key={idx}
            w={200}
            component={Link}
            to={service.path}
            onClick={(e) => service.disabled && e.preventDefault()}
          >
            <Card.Section>
              <Center>
                <service.icon size={100} />
              </Center>
            </Card.Section>
            <Center>
              <Stack align="center">
                <Text weight={700}>{service.title}</Text>
                <Text>{service.description}</Text>
              </Stack>
            </Center>
          </Card>
        ))}
      </Flex>
      Well-Played.gg is made by developers for developers, and is above all a
      Backend as a Service. We provide a set of tools to help you build your
      application faster, and we take care of the infrastructure so you can
      focus on what you do best: building your application.
      <br />
      We also provide finished products (such as the Platform) that you can use
      as a starting point to build your application, but remember that basic
      knowledge of web development is required to use our services properly.
      <br />
      <br />
      In this regard, we provide a set of services that you can use to build
      your application.
      <br />
      These services can be accessed either through the Console, or through our
      GraphQL API.
      <br />
      <Box w="auto" mx="auto">
        <Group position="center" mb={5}>
          <Button onClick={toggle}>Show API Documentation</Button>
        </Group>
        <Alert icon={<IconInfoCircle />} color="indigo" variant="outline">
          <Text align="center" fs="italic">
            You can find service-sepcific documentation by clicking on the
            service you are interested in, then on their "Documentation" tab.
          </Text>{" "}
        </Alert>

        <Collapse in={opened}>
          <Stack>
            <Title>Start using the API</Title>
            <Title order={2}>GraphQL</Title>
            <Text>
              Our API uses GraphQL to serve requests over <code>HTTPS</code> and{" "}
              <code>WebSockets</code>.<br />
              <Anchor
                href="https://graphql.org/learn/serving-over-http/"
                target="_blank"
                rel="noopener noreferrer"
              >
                You can learn more about GraphQL here.
              </Anchor>
            </Text>
            <Title order={2}>API URLs</Title>
            <Text>
              You can interact with the API by using the following URL:{" "}
              <Anchor
                href="https://api.warrior.well-played.gg/graphql"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://api.warrior.well-played.gg/graphql
              </Anchor>
              <br />
              Because the API uses strict GraphQL Schemas, you can easily
              generate an SDK and types by using{" "}
              <Anchor
                href="https://the-guild.dev/graphql/codegen"
                target="_blank"
                rel="noopener noreferrer"
              >
                a code generator
              </Anchor>
              .<br />
              If you want to play with the API and have more precise
              documentations, we provide a Playground through the following URL:{" "}
              <Anchor
                href="https://api.warrior.well-played.gg/graphql"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://api.warrior.well-played.gg/graphql
              </Anchor>
              .
            </Text>
            <Title order={2}>
              Required headers
              <a
                className="hash-link"
                href="#required-headers"
                title="Direct link to heading"
              >
                ​
              </a>
            </Title>
            <Text>
              To be able to interact with the API, you will need to send the
              header <code>organization-id</code> with all your requests to the
              API and setting its value to the <code>ID</code> of the{" "}
              <code>Organization</code> you want your app to be able to interact
              with.
              <br />
              This header is not needed on the following endpoints:{" "}
              <code>findOrganizationFromDomain</code>.<br />
              example:
            </Text>

            <Prism language="graphql">
              {`
              curl -X POST -H 'organization-id: ORGANIZATION_ID_HERE' \\
  -d '{...}' https://api.warrior.well-played.gg
                `}
            </Prism>
          </Stack>
        </Collapse>
      </Box>
    </Stack>
  );
};
