import { gql, useMutation, useQuery } from "@apollo/client";
import { AutoDataTable } from "../../components/AutoDataTable";
import { useNavigate, useParams } from "react-router-dom";
import { PlatformCustomDomainData } from "../../../../gql/graphql";
import { CreationForm } from "../../components/form/CreationForm";
import * as yup from "yup";
import { notifications } from "@mantine/notifications";
import { graphql } from "gql.tada";
import {
  ActionIcon,
  Anchor,
  Badge,
  Card,
  Center,
  Group,
  Loader,
  RingProgress,
  SimpleGrid,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconBrandPagekit,
  IconCopy,
  IconRefresh,
  IconTournament,
  IconUserPlus,
} from "@tabler/icons-react";
import { useState } from "react";
import { useClipboard } from "@mantine/hooks";
import { AbbrevitatedNumber } from "../../components/AbbrevitatedNumber";

const PlatformQuery = graphql(`
  query platform($platformId: ID!) {
    platform(platformId: $platformId) {
      id
      name
      updatedAt
      createdAt
      customDomains {
        configuration {
          status
        }
        domain
      }
    }
  }
`);

const PlatformRegisterCustomDomainMutation = graphql(`
  mutation platformRegisterCustomDomain($platformId: ID!, $hostname: String!) {
    platformRegisterCustomDomain(platformId: $platformId, hostname: $hostname) {
      hostname
    }
  }
`);

const PlatformForceCustomDomainRecheck = graphql(`
  mutation platformForceCustomDomainRecheck($hostname: String!) {
    platformForceCustomDomainRecheck(hostname: $hostname) {
      hostname
    }
  }
`);

export const RegisterPlatformDomain = () => {
  const navigate = useNavigate();
  const platformId = useParams().platformId as string;
  const [registerNewDomain] = useMutation(PlatformRegisterCustomDomainMutation);

  return (
    <CreationForm
      title={"Register a new domain"}
      schemaDefinition={{
        hostname: {
          type: "INPUT",
          label: "Domain",
          yupConfig: yup.string().required(),
          inputType: "text",
          placeholder: "Enter the domain to register",
        },
      }}
      onSubmit={async (values) => {
        await registerNewDomain({
          variables: {
            platformId,
            hostname: values.hostname,
          },
        }).then((data) => {
          if (data?.errors && data.errors.length > 0) {
            throw new Error(data.errors[0].message);
          }
        });
        notifications.show({
          title: "Domain registered",
          message: "The domain has been registered successfully",
          color: "green",
          autoClose: 3000,
        });
        navigate("../domains");
      }}
    />
  );
};

export const PlatformDomains = () => {
  const platformId = useParams().platformId;
  const clipboard = useClipboard();
  const [refreshing, setRefreshing] = useState(false);
  const [forceRecheck] = useMutation(PlatformForceCustomDomainRecheck);

  return (
    <AutoDataTable
      createButtonText="Register new domain"
      deleteMutation={gql`
        mutation ($id: String!) {
          platformRemoveCustomDomain(hostname: $id)
        }
      `}
      query={gql`
        query ($platformId: ID!) {
          platformCustomDomains(platformId: $platformId) {
            domain
            configuration {
              hostname
              status
              ssl {
                status
                validation_errors {
                  message
                }

                validation_records {
                  txt_name
                  txt_value
                }
              }
              ownership_verification {
                name
                value
              }
              verification_errors
            }
          }
        }
      `}
      queryVariables={{
        platformId: platformId,
      }}
      idField="domain"
      columns={[
        {
          accessor: "hostname",
          title: "Hostname",
          rawElement: (domain: PlatformCustomDomainData) => (
            <>{domain.configuration?.hostname}</>
          ),
        },
        {
          accessor: "status",
          title: "CNAME status",
          description: "The status of the domain validation",
          rawElement: (platform: PlatformCustomDomainData) => (
            <Badge
              color={
                platform.configuration?.status === "active" ? "green" : "orange"
              }
            >
              {platform.configuration?.status}
            </Badge>
          ),
        },
        {
          accessor: "ownershipVerification",
          title: "DNS values",
          rawElement: (platform: PlatformCustomDomainData) => (
            <Stack spacing={0}>
              <Group>
                <Text>Your domain CNAME target: cname.well-played.gg</Text>
                <Tooltip label="Copy">
                  <ActionIcon
                    onClick={() => {
                      clipboard.copy("cname.well-played.gg");
                    }}
                  >
                    <IconCopy />
                  </ActionIcon>
                </Tooltip>
              </Group>
              <Text>Records to add to your domain as TXT records</Text>
              {platform.configuration?.ownership_verification && (
                <Group spacing={0}>
                  <Text>
                    {platform.configuration?.ownership_verification?.name}
                  </Text>
                  <Tooltip label="Copy">
                    <ActionIcon
                      onClick={() => {
                        clipboard.copy(
                          platform.configuration?.ownership_verification?.name,
                        );
                      }}
                    >
                      <IconCopy />
                    </ActionIcon>
                  </Tooltip>
                  :{" "}
                  <Text>
                    {platform.configuration?.ownership_verification?.value}
                  </Text>
                  <Tooltip label="Copy">
                    <ActionIcon
                      onClick={() => {
                        clipboard.copy(
                          platform.configuration?.ownership_verification?.value,
                        );
                      }}
                    >
                      <IconCopy />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              )}
              {platform.configuration?.ssl?.validation_records?.map(
                (record, idx) => (
                  <Group spacing={0} key={idx}>
                    <Text>{record.txt_name}</Text>
                    <Tooltip label="Copy">
                      <ActionIcon
                        onClick={() => {
                          clipboard.copy(record.txt_name);
                        }}
                      >
                        <IconCopy />
                      </ActionIcon>
                    </Tooltip>
                    :{" "}
                    <Text>
                      {record.txt_value}
                      <Tooltip label="Copy">
                        <ActionIcon
                          onClick={() => {
                            clipboard.copy(record.txt_value);
                          }}
                        >
                          <IconCopy />
                        </ActionIcon>
                      </Tooltip>
                    </Text>
                  </Group>
                ),
              )}
            </Stack>
          ),
        },
        {
          accessor: "sslstatus",
          title: "SSL certificate status",
          description: "The status of the SSL certificate validation",
          rawElement: (platform: PlatformCustomDomainData) => (
            <>
              <Badge
                color={
                  platform.configuration?.ssl?.status === "active"
                    ? "green"
                    : "orange"
                }
              >
                {platform.configuration?.ssl?.status}
              </Badge>
              <br />
              {platform.configuration?.ssl?.validation_errors?.map(
                (error, idx) => (
                  <Text color="red" key={idx}>
                    {error.message}
                  </Text>
                ),
              )}
            </>
          ),
        },
        {
          accessor: "validationErrors",
          title: "Validation errors",
          description: "The errors that occurred during the validation process",
          rawElement: (platform: PlatformCustomDomainData) => (
            <Stack spacing={0}>
              {platform.configuration?.verification_errors?.map(
                (error, idx) => <Text key={idx}>{error}</Text>,
              )}
              {platform.configuration?.ssl?.validation_errors?.map(
                (error, idx) => <Text key={idx}>{error.message}</Text>,
              )}
            </Stack>
          ),
        },
        {
          accessor: "refresh",
          title: "",
          rawElement: (platform: PlatformCustomDomainData) => (
            <ActionIcon
              onClick={async () => {
                await forceRecheck({
                  variables: {
                    hostname: platform.domain,
                  },
                }).then((data) => {
                  if (data?.errors && data.errors.length > 0) {
                    throw new Error(data.errors[0].message);
                  }
                  window.location.reload();
                });
              }}
            >
              <IconRefresh />
            </ActionIcon>
          ),
        },
      ]}
    />
  );
};

export const Platform = () => {
  const platformId = useParams().platformId as string;
  const { data, loading } = useQuery(PlatformQuery, {
    variables: {
      platformId,
    },
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Title>Dashboard</Title>
      <SimpleGrid cols={2}>
        <Card>
          <Card.Section>
            <Group position="apart" p={10}>
              <Group spacing={10}>
                <IconBrandPagekit />
                <Text size="md" weight={700}>
                  {data?.platform?.name}
                </Text>
              </Group>
            </Group>
          </Card.Section>
          <Text fw={700}>Domains</Text>
          <SimpleGrid cols={2}>
            {data?.platform?.customDomains?.map((domain) => (
              <Anchor
                key={domain.domain}
                href={`https://${domain.domain}`}
                target="_blank"
              >
                {domain.domain}
              </Anchor>
            ))}
          </SimpleGrid>
        </Card>
      </SimpleGrid>
    </>
  );
};
