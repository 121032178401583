import { gql, useMutation, useQuery } from "@apollo/client";
import { AutoDataTable } from "../../../components/AutoDataTable";
import {
  ActionIcon,
  AutocompleteItem,
  Button,
  Group,
  Loader,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import * as yup from "yup";
import { notifications } from "@mantine/notifications";
import { CreationForm } from "../../../components/form/CreationForm";
import { useNavigate, useParams } from "react-router-dom";
import { graphql } from "gql.tada";
import {
  OrganizationSkillRating,
  SkillRatingType,
} from "../../../../../gql/graphql";
import { IconDeviceGamepad, IconX } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { useListState } from "@mantine/hooks";
import { PlayerSearch } from "../../../components/form/PlayerSearch";
import { useState } from "react";

const SkillRatingQuery = graphql(`
  query organizationSkillRating($id: ID!) {
    organizationSkillRating(id: $id) {
      id
      name
      type
      description
      configuration {
        updateLeaderboardId
      }
    }
  }
`);

const ListLeaderboardsQuery = graphql(`
  query leaderboards {
    leaderboards(page: { first: 100 }) {
      nodes {
        name
        id
      }
    }
  }
`);

const SkillRatingUpdateMutation = graphql(`
  mutation organizationSkillRatingUpdate(
    $id: ID!
    $input: OrganizationSkillRatingUpdateInput!
  ) {
    organizationSkillRatingUpdate(id: $id, input: $input) {
      id
    }
  }
`);

const SkillRatingCreateMutation = graphql(`
  mutation organizationSkillRatingCreate(
    $input: OrganizationSkillRatingCreateInput!
  ) {
    organizationSkillRatingCreate(input: $input) {
      id
    }
  }
`);

export const CreateOrUpdateSkillRating = () => {
  const navigate = useNavigate();
  const skillRatingId = useParams().skillRatingId as string;
  const { data, loading } = useQuery(SkillRatingQuery, {
    variables: {
      id: skillRatingId,
    },
    skip: !skillRatingId,
  });
  const { data: leaderboardData, loading: leaderboardLoading } = useQuery(
    ListLeaderboardsQuery,
  );
  const [update] = useMutation(SkillRatingUpdateMutation);
  const [create] = useMutation(SkillRatingCreateMutation);

  if (loading || leaderboardLoading) {
    return <Loader />;
  }

  return (
    <CreationForm
      title={skillRatingId ? "Update skill rating" : "Create skill rating"}
      schemaDefinition={{
        name: {
          type: "INPUT",
          label: "Name",
          yupConfig: yup.string().required(),
          inputType: "text",
          defaultValue: data?.organizationSkillRating?.name,
          placeholder: "Enter the skill rating name",
        },
        type: {
          label: "Type",
          type: "SELECT",
          options: Object.values(SkillRatingType).map((v) => ({
            label: v,
            value: v,
          })),
          yupConfig: yup.string().required(),
          defaultValue: data?.organizationSkillRating?.type,
          subtext: (
            <>
              Bradley-Terry rating models follow a logistic distribution over a
              player's skill, similar to Glicko.
              <br />
              Thurstone-Mosteller rating models follow a gaussian distribution,
              similar to TrueSkill. Gaussian CDF/PDF functions differ in
              implementation from system to system (they're all just chebyshev
              approximations anyway). The accuracy of this model isn't usually
              as great either, but tuning this with an alternative gamma
              function can improve the accuracy if you really want to get into
              it.
              <br />
              Full pairing should have more accurate ratings over partial
              pairing, however in high k games (like a 100+ person marathon
              race), Bradley-Terry and Thurstone-Mosteller models need to do a
              calculation of joint probability which involves is a k-1
              dimensional integration, which is computationally expensive. Use
              partial pairing in this case, where players only change based on
              their neighbors.
              <br />
              Plackett-Luce is a generalized Bradley-Terry model for k ≥ 3
              teams. It scales best.
            </>
          ),
        },
        description: {
          label: "Description",
          type: "INPUT",
          yupConfig: yup.string().required(),
          inputType: "text",
          defaultValue: data?.organizationSkillRating?.description,
          placeholder: "Enter the skill rating description",
        },
        updateLeaderboardId: {
          label: "Leaderboard to update",
          type: "SELECT",
          options:
            leaderboardData?.leaderboards.nodes
              .map((leaderboard) => ({
                label: leaderboard.name,
                value: leaderboard.id as string,
              }))
              .concat([
                {
                  label: "None",
                  value: "",
                },
              ]) ?? [],
          yupConfig: yup.string(),
          defaultValue:
            data?.organizationSkillRating?.configuration?.updateLeaderboardId,
          subtext:
            "Optional leaderboard to update when a player skill rating is updated, it uses a custom algorithm providing from -20 to +20 points depending on who won the match",
        },
      }}
      onSubmit={async (values) => {
        if (skillRatingId) {
          await update({
            variables: {
              id: skillRatingId,
              input: {
                name: values.name,
                type: values.type,
                description: values.description,
                configuration: {
                  updateLeaderboardId:
                    values.updateLeaderboardId === ""
                      ? undefined
                      : values.updateLeaderboardId,
                },
              },
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
          });
          notifications.show({
            title: "Page updated",
            message: "The page has been updated successfully",
            color: "green",
            autoClose: 3000,
          });
        } else {
          await create({
            variables: {
              input: {
                name: values.name,
                type: values.type,
                description: values.description,
                configuration: {
                  updateLeaderboardId:
                    values.updateLeaderboardId === ""
                      ? undefined
                      : values.updateLeaderboardId,
                },
              },
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
            navigate(
              ("../mmr/" +
                data?.data?.organizationSkillRatingCreate.id) as string,
            );
          });
        }
      }}
    />
  );
};

const OrganizationSkillRatingsRegisterMatchMutation = graphql(`
  mutation organizationSkillRatingsRegisterMatch(
    $input: OrganizationSkillRatingsRegisterMatchInput!
    $skillRatingId: ID!
  ) {
    organizationSkillRatingsRegisterMatch(
      input: $input
      organizationSkillRatingId: $skillRatingId
    ) {
      id
    }
  }
`);

const RegisterMatchModal = ({ skillRatingId }: { skillRatingId: string }) => {
  const [loading, setLoading] = useState(false);
  const [teams, { append, remove, setItemProp, setState }] = useListState<{
    players: { id: string; username: string }[];
  }>([
    {
      players: [],
    },
    {
      players: [],
    },
  ]);
  const [registerMatch] = useMutation(
    OrganizationSkillRatingsRegisterMatchMutation,
  );

  return (
    <>
      <Button
        onClick={() =>
          append({
            players: [],
          })
        }
        disabled={teams.length >= 10}
      >
        Add a team
      </Button>
      <Text color="red">
        Team order matters! The first team will be considered as the winner, the
        2nd is 2nd place and so on
      </Text>
      {teams.map((team, index) => (
        <Stack key={index}>
          <Group spacing={0}>
            <Title order={4}>Team #{index + 1}</Title>
            {index > 1 && (
              <ActionIcon
                color="red"
                onClick={() => {
                  remove(index);
                }}
              >
                <IconX />
              </ActionIcon>
            )}
          </Group>
          <PlayerSearch
            onChange={(data) => {
              setItemProp(
                index,
                "players",
                team.players.concat({
                  id: data.id,
                  username: data.username,
                }),
              );
            }}
          />
          <Group spacing={0}>
            {team.players?.map((player, playerIndex) => (
              <Group key={playerIndex} spacing={0}>
                <Text>{player.username}</Text>
                <ActionIcon
                  onClick={() => {
                    setItemProp(
                      index,
                      "players",
                      (team.players ?? []).filter((_, i) => i !== playerIndex),
                    );
                  }}
                >
                  X
                </ActionIcon>
                {playerIndex > 0 && " | "}
              </Group>
            ))}
          </Group>
        </Stack>
      ))}
      {teams.some(
        (team) => team.players.length === 0 || team.players.length > 100,
      ) ? (
        <Text color="red">
          Each team must have at least one player and at most 100 players
        </Text>
      ) : null}
      <Button
        disabled={teams.some(
          (team) => team.players.length === 0 || team.players.length > 100,
        )}
        loading={loading}
        onClick={() => {
          setLoading(true);
          registerMatch({
            variables: {
              skillRatingId,
              input: {
                teams: teams.map((team) => ({
                  players: team.players.map((p) => p.id as string),
                })),
              },
            },
          }).then((data) => {
            setLoading(false);
            if (data?.errors && data.errors.length > 0) {
              notifications.show({
                title: "Error",
                message: data.errors[0].message,
                color: "red",
              });
            } else {
              setState([
                {
                  players: [],
                },
                {
                  players: [],
                },
              ]);
              notifications.show({
                title: "Match registered",
                message: "The match has been registered successfully",
                color: "green",
              });
            }
          });
        }}
      >
        Register the match result
      </Button>
    </>
  );
};

export const SkillRatings = () => {
  return (
    <>
      <Title>Skill Ratings</Title>
      <AutoDataTable
        createButtonText="New skill rating"
        deleteMutation={gql`
          mutation ($id: ID!) {
            organizationSkillRatingDelete(id: $id)
          }
        `}
        query={gql`
          query organizationSkillRatings {
            organizationSkillRatings {
              id
              name
              type
            }
          }
        `}
        columns={[
          {
            accessor: "name",
            title: "Name",
          },
          {
            accessor: "type",
            title: "Type",
            rawElement: (item: OrganizationSkillRating) => item.type,
          },
          {
            accessor: "action",
            title: "",
            rawElement: (item) => (
              <Tooltip label="Register a match">
                <ActionIcon
                  color="green"
                  onClick={() => {
                    modals.open({
                      title: <b>Register a match</b>,
                      children: <RegisterMatchModal skillRatingId={item.id} />,
                    });
                  }}
                >
                  <IconDeviceGamepad />
                </ActionIcon>
              </Tooltip>
            ),
          },
        ]}
      />
    </>
  );
};
