import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { graphql } from "gql.tada";

const GET_PLAYERS_QUERY = graphql(`
  query players($ids: [ID!]!, $page: PageInfo!) {
    players(ids: $ids, page: $page) {
      nodes {
        id
        username
        ownerId
        customFields {
          property
          value
          name
          order
        }
        identities {
          properties {
            property
            value
          }
          organizationIdentityProvider {
            name
          }
        }
      }
    }
  }
`);

export const usePlayers = ({
  playerIds,
  skip,
}: {
  playerIds?: string[];
  skip?: boolean;
}) => {
  const [players, setPlayers] = useState<
    {
      id: unknown;
      username: string;
      ownerId: unknown;
      customFields: {
        property: string;
        value: string;
        name: string;
        order: number;
      }[];
      identities: {
        organizationIdentityProvider: {
          name: string;
        } | null;
        properties: {
          property: string;
          value: string;
        }[];
      }[];
    }[]
  >([]);
  const [loading, setLoading] = useState(!skip);

  const [fetch] = useLazyQuery(GET_PLAYERS_QUERY);

  const refetch = async (playerIds: string[]) => {
    const result: {
      id: unknown;
      username: string;
      ownerId: unknown;
      customFields: {
        property: string;
        value: string;
        name: string;
        order: number;
      }[];
      identities: {
        organizationIdentityProvider: {
          name: string;
        } | null;
        properties: {
          property: string;
          value: string;
        }[];
      }[];
    }[] = [];
    while (playerIds.length > 0) {
      result.push(
        ...((
          await fetch({
            variables: {
              page: { first: 100 },
              ids: playerIds.splice(0, 100) as never,
            },
          })
        ).data?.players?.nodes.map((player) => ({
          ...player,
          identities: player.identities ?? [],
        })) ?? []),
      );
    }
    setPlayers(result);
  };

  useEffect(() => {
    if (!skip && playerIds) {
      setLoading(true);
      refetch(playerIds).finally(() => setLoading(false));
    }
  }, [skip, playerIds]);

  return {
    results: players,
    refetch,
    loading,
  };
};
