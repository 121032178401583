const ENABLE_STG_TEST = process.env.NODE_ENV === "development";
const LOCAL =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1";
export const IS_LOCAL = LOCAL && !ENABLE_STG_TEST;
export const IS_STG =
  ENABLE_STG_TEST || window.location.hostname === "console.stg.well-played.gg";
export const API_HOSTNAME = IS_LOCAL
  ? "localhost:3000/"
  : IS_STG
  ? "api.warrior.stg.well-played.gg/"
  : "api.warrior.well-played.gg/";
export const GQL_URL = API_HOSTNAME + "graphql";
