import { gql, useMutation, useQuery } from "@apollo/client";
import {
  DataRetrievalConfig,
  OAuthClientConfiguration,
  OauthEndpointAuthMethod,
  OrganizationIdentityProvider,
  PublicIdentityProvider,
  StepType,
} from "../../../../../gql/graphql";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CreationForm } from "../../../components/form/CreationForm";
import * as yup from "yup";
import { useEffect, useState } from "react";
import {
  ActionIcon,
  Anchor,
  Button,
  Card,
  Group,
  Loader,
  ScrollArea,
  SimpleGrid,
  Stack,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { DataTable } from "mantine-datatable";
import { IconCopy, IconTrash } from "@tabler/icons-react";
import { map } from "lodash";
import { useClipboard } from "@mantine/hooks";

export const UpdateIdentityProvider = () => {
  const mantineTheme = useMantineTheme();
  const navigate = useNavigate();
  const idpId = useParams().idpId;
  const [selectedIdp, setSelectedIdp] = useState<PublicIdentityProvider>();
  const { loading: identityProvidersLoading, data: identityProviderData } =
    useQuery<{
      getAvailableRootIdentityProviders: PublicIdentityProvider[];
    }>(gql`
      query {
        getAvailableRootIdentityProviders {
          id
          name
          availability
        }
      }
    `);
  const { loading, data } = useQuery<{
    identityProvider: OrganizationIdentityProvider;
  }>(
    gql`
      query ($id: ID!) {
        identityProvider(id: $id) {
          id
          name
          description
          allowLogin
          icon
          identityProviderId
          parentIdentityProvider {
            id
            name
          }
          configuration {
            __typename
            ... on OAuthClientConfiguration {
              clientId
              authorizationUrl
              redirectUrl
              tokenEndpoint
              tokenEndpointAuthMethod
              clientSecret
              dataRetrievers {
                mappingConfiguration {
                  mappings {
                    mappedTo
                    path
                  }
                }
                headers {
                  value
                  name
                }
                url
              }
            }
          }
        }
      }
    `,
    {
      variables: { id: idpId },
      skip: !idpId || identityProvidersLoading,
      onCompleted: (data) =>
        setSelectedIdp(
          identityProviderData?.getAvailableRootIdentityProviders.find(
            (idp) => idp.id === data.identityProvider.id,
          ) ??
            ({
              id: "custom",
            } as any),
        ),
    },
  );
  const [createIdentityProvider] = useMutation(gql`
    mutation (
      $name: String!
      $description: String!
      $allowLogin: Boolean!
      $icon: String
      $parentIdentityProviderId: ID!
      $configuration: OAuthClientConfigurationInput!
    ) {
      createIdentityProvider(
        input: {
          name: $name
          description: $description
          allowLogin: $allowLogin
          icon: $icon
          identityProviderId: $parentIdentityProviderId
          oauth2Configuration: $configuration
        }
      ) {
        id
      }
    }
  `);
  const [updateIdentityProvider] = useMutation(gql`
    mutation (
      $id: ID!
      $name: String!
      $description: String!
      $allowLogin: Boolean!
      $icon: String
      $configuration: OAuthClientConfigurationInput!
    ) {
      updateIdentityProvider(
        providerId: $id
        input: {
          name: $name
          description: $description
          allowLogin: $allowLogin
          icon: $icon
          oauth2Configuration: $configuration
        }
      ) {
        id
      }
    }
  `);

  if (loading || identityProvidersLoading) {
    return <Loader />;
  }

  if (!selectedIdp) {
    return (
      <Stack>
        <Title>Select an identity provider type</Title>
        <SimpleGrid cols={4}>
          {identityProviderData?.getAvailableRootIdentityProviders
            .concat([
              {
                id: "custom",
                name: "Custom OAuth2",
                requirements: {
                  requiredFields: [],
                },
              } as unknown as PublicIdentityProvider,
            ])
            .map((idp) => (
              <Card
                withBorder={true}
                onClick={() => {
                  setSelectedIdp(idp);
                }}
                style={{
                  cursor: "pointer",
                  borderColor:
                    selectedIdp === idp
                      ? mantineTheme.colors.cyan[7]
                      : undefined,
                }}
              >
                <Text fw={500}>{idp.name}</Text>

                <ScrollArea h={100}>
                  <Text size="sm" c="dimmed">
                    {idp.description}
                  </Text>
                </ScrollArea>
              </Card>
            ))}
        </SimpleGrid>
      </Stack>
    );
  }

  return (
    <CreationForm
      title={
        idpId
          ? `Update identity provider ${
              data?.identityProvider?.identityProviderId
                ? `(type: ${data?.identityProvider?.parentIdentityProvider?.name})`
                : ""
            }`
          : "Add identity provider"
      }
      schemaDefinition={{
        name: {
          type: "INPUT",
          label: "Name",
          placeholder: "Name",
          defaultValue: data?.identityProvider?.name,
          yupConfig: yup.string().required(),
          inputType: "text",
        },
        description: {
          type: "TEXTAREA",
          label: "Description",
          yupConfig: yup.string().required(),
          defaultValue: data?.identityProvider?.description,
        },
        allowLogin: {
          type: "CHECKBOX",
          defaultValue: data?.identityProvider?.allowLogin ?? false,
          label: "Allow login",
          info: (
            <Text>
              If enabled, users will be able to login using this identity
              <br />
              provider to create a new account. If you only need to use this
              <br />
              identity provider to link an existing account, you can disable
              <br />
              this option.
            </Text>
          ),
          yupConfig: yup.boolean(),
        },
        clientId: {
          type: "INPUT",
          label: "Client ID",
          placeholder: "Client ID",
          defaultValue: data?.identityProvider?.configuration?.clientId,
          yupConfig: yup.string().required(),
          info: (
            <Text>
              The client ID is provided by the OAuth2 provider. For example, if
              <br />
              you are using Google, you can find the client ID in the
              <br />
              "Credentials" section of the Google Cloud Console.
            </Text>
          ),
          inputType: "text",
        },
        clientSecret: {
          type: "INPUT",
          label: "Client secret",
          placeholder: "Client secret",
          defaultValue: data?.identityProvider?.configuration?.clientSecret,
          yupConfig: yup.string().required(),
          info: (
            <Text>
              The client secret is provided by the OAuth2 provider. For example,
              <br />
              if you are using Google, you can find the client secret in the
              <br />
              "Credentials" section of the Google Cloud Console.
            </Text>
          ),
          inputType: "password",
        },
        redirectUrl: {
          type: "INPUT",
          label: "Redirect URL",
          placeholder: "Redirect URL",
          defaultValue:
            data?.identityProvider?.configuration?.redirectUrl ??
            `${window.location.origin.replace(
              "console.",
              "idp.warrior.",
            )}/login/\${identityProviderId}`,
          yupConfig: yup.string().required(),
          inputType: "text",
          info: (
            <Text>
              The redirect URL is provided by the OAuth2 provider. For example,
              <br />
              if you are using Google, you can find the redirect URL in the
              <br />
              "Credentials" section of the Google Cloud Console. It should be
              <br />
              set to redirect on your application frontend.
            </Text>
          ),
          subtext: (
            <Text>
              {
                "You can use variables using the syntax ${variableName}, available variables for Oauth2: ${clientId}, ${redirectUrl}, ${identityProviderId}"
              }
              <br />
              <Text weight={700}>
                The default redirect URL that is already provided here can be
                used to configure the redirect URL of your OAuth2 provider. If
                set to this URL, users will be automatically authenticated
                without having to be redirected to the login page.
                <br />
                If you decide to use the default redirect URL, you can retrieve
                it after saving the identity provider, on the identity provider
                list page.
                <br />
                If you want to manage the login process yourself, you can set
                the redirect URL to your login page and handle the login process
                yourself (see{" "}
                <Anchor
                  span
                  component={Link}
                  to={"/apps/documentations"}
                  target={"_blank"}
                >
                  apps documentations
                </Anchor>{" "}
                to learn more).
              </Text>
            </Text>
          ),
        },
        ...((selectedIdp.id === "custom" &&
          !data?.identityProvider?.identityProviderId && {
            authorizationUrl: {
              type: "INPUT",
              label: "Authorization URL",
              placeholder: "Authorization URL",
              defaultValue: (
                data?.identityProvider
                  ?.configuration as OAuthClientConfiguration
              )?.authorizationUrl?.valueOf(),
              yupConfig: yup.string().required(),
              inputType: "text",
              info: (
                <Text>
                  The authorization URL is provided by the OAuth2 provider.
                  <br />
                  It is usually found in the documentation of the OAuth2
                  provider.
                </Text>
              ),
              subtext:
                "You can use variables using the syntax ${variableName}, available variables for Oauth2: ${clientId}, ${redirectUrl}, ${identityProviderId}",
            },
            tokenEndpoint: {
              type: "INPUT",
              label: "Token endpoint",
              placeholder: "Token endpoint",
              defaultValue: (
                data?.identityProvider
                  ?.configuration as OAuthClientConfiguration
              )?.tokenEndpoint?.valueOf(),
              yupConfig: yup.string().required(),
              info: (
                <Text>
                  The token endpoint is provided by the OAuth2 provider.
                  <br />
                  It is usually found in the documentation of the OAuth2
                  provider.
                </Text>
              ),
              inputType: "text",
            },
            tokenEndpointAuthMethod: {
              type: "SELECT",
              label: "Token endpoint auth method",
              defaultValue: (
                data?.identityProvider
                  ?.configuration as OAuthClientConfiguration
              )?.tokenEndpointAuthMethod?.valueOf(),
              options: [
                {
                  label: "Client secret post",
                  value: OauthEndpointAuthMethod.ClientSecretPost,
                },
                {
                  label: "Client secret basic",
                  value: OauthEndpointAuthMethod.ClientSecretBasic,
                },
                {
                  label: "Client Secret JWT",
                  value: OauthEndpointAuthMethod.ClientSecretJwt,
                },
                {
                  label: "Private Key JWT",
                  value: OauthEndpointAuthMethod.PrivateKeyJwt,
                },
                {
                  label: "Tls Client Auth",
                  value: OauthEndpointAuthMethod.TlsClientAuth,
                },
                {
                  label: "Self Signed TLS Client Auth",
                  value: OauthEndpointAuthMethod.SelfSignedTlsClientAuth,
                },
                {
                  label: "None",
                  value: OauthEndpointAuthMethod.None,
                },
              ],
              info: (
                <Text>
                  The token endpoint auth method is provided by the OAuth2
                  provider.
                  <br />
                  It is usually found in the documentation of the OAuth2
                  provider.
                  <br />
                  If you are unsure, you can try "Client secret post".
                </Text>
              ),
              yupConfig: yup.string().required(),
            },
          }) ||
          {}),
        ...(((selectedIdp.id === "custom" || idpId) && {
          dataRetrievers: {
            type: "DYNAMIC",
            label: "Data retrievers",
            defaultValue: (
              data?.identityProvider?.configuration as OAuthClientConfiguration
            )?.dataRetrievers?.map((value) => ({
              url: value.url,
              headers: value.headers.map((header) => ({
                name: header.name,
                value: header.value,
              })),
              mappingConfiguration: {
                mappings: value.mappingConfiguration.mappings.map(
                  (mapping) => ({
                    mappedTo: mapping.mappedTo,
                    path: mapping.path,
                  }),
                ),
              },
            })),
            subtext:
              "Data retrievers are used to retrieve data from the OAuth2 provider. You can use variables using the syntax ${variableName}, available variables for Oauth2: ${clientId}, ${redirectUrl}, ${identityProviderId}",
            yupConfig: yup.array().of(
              yup.object().shape({
                url: yup.string().required(),
                headers: yup.array().of(
                  yup.object().shape({
                    name: yup.string().required(),
                    value: yup.string().required(),
                  }),
                ),
                mappingConfiguration: yup.object().shape({
                  mappings: yup.array().of(
                    yup.object().shape({
                      mappedTo: yup.string().required(),
                      path: yup.string().required(),
                    }),
                  ),
                }),
              }),
            ),
            element: (onChange) => {
              const [dataRetrievers, setDataRetrievers] = useState<
                DataRetrievalConfig[]
              >(
                data?.identityProvider?.configuration?.dataRetrievers?.map(
                  (value) => ({
                    url: value.url,
                    headers: value.headers.map((header) => ({
                      name: header.name,
                      value: header.value,
                    })),
                    mappingConfiguration: {
                      mappings: value.mappingConfiguration.mappings.map(
                        (mapping) => ({
                          mappedTo: mapping.mappedTo,
                          path: mapping.path,
                        }),
                      ),
                    },
                  }),
                ) ?? [],
              );

              useEffect(() => {
                onChange(dataRetrievers);
              }, [dataRetrievers]);

              return (
                <>
                  <Button
                    onClick={() => {
                      setDataRetrievers([
                        ...dataRetrievers,
                        {
                          url: "",
                          headers: [],
                          mappingConfiguration: {
                            mappings: [],
                          },
                        },
                      ]);
                    }}
                  >
                    Add data retriever
                  </Button>
                  <DataTable
                    columns={[
                      {
                        title: "URL",
                        accessor: "",
                        render: (dataRetriever, index) => (
                          <input
                            placeholder="ex: https://api.example.com/users/me"
                            value={dataRetriever.url}
                            onChange={(e) => {
                              const url = e.target.value;
                              setDataRetrievers([
                                ...dataRetrievers.slice(0, index),
                                {
                                  ...dataRetriever,
                                  url,
                                },
                                ...dataRetrievers.slice(index + 1),
                              ]);
                            }}
                          />
                        ),
                      },
                      {
                        title: "Headers",
                        accessor: "",
                        render: (dataRetriever, index) => (
                          <>
                            <Button
                              onClick={() => {
                                setDataRetrievers([
                                  ...dataRetrievers.slice(0, index),
                                  {
                                    ...dataRetriever,
                                    headers: [
                                      ...dataRetriever.headers,
                                      {
                                        name: "",
                                        value: "",
                                      },
                                    ],
                                  },
                                  ...dataRetrievers.slice(index + 1),
                                ]);
                              }}
                            >
                              Add header
                            </Button>
                            <DataTable
                              records={dataRetriever.headers}
                              columns={[
                                {
                                  title: "Name",
                                  accessor: "",
                                  render: (header, headerIndex) => (
                                    <input
                                      placeholder="ex: header-name"
                                      value={header.name}
                                      onChange={(e) => {
                                        const name = e.target.value;
                                        setDataRetrievers([
                                          ...dataRetrievers.slice(0, index),
                                          {
                                            ...dataRetriever,
                                            headers: [
                                              ...dataRetriever.headers.slice(
                                                0,
                                                headerIndex,
                                              ),
                                              {
                                                ...header,
                                                name,
                                              },
                                              ...dataRetriever.headers.slice(
                                                headerIndex + 1,
                                              ),
                                            ],
                                          },
                                          ...dataRetrievers.slice(index + 1),
                                        ]);
                                      }}
                                    />
                                  ),
                                },
                                {
                                  title: "Value",
                                  accessor: "",
                                  render: (header, headerIndex) => (
                                    <input
                                      placeholder="ex: My header value"
                                      value={header.value}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        setDataRetrievers([
                                          ...dataRetrievers.slice(0, index),
                                          {
                                            ...dataRetriever,
                                            headers: [
                                              ...dataRetriever.headers.slice(
                                                0,
                                                headerIndex,
                                              ),
                                              {
                                                ...header,
                                                value,
                                              },
                                              ...dataRetriever.headers.slice(
                                                headerIndex + 1,
                                              ),
                                            ],
                                          },
                                          ...dataRetrievers.slice(index + 1),
                                        ]);
                                      }}
                                    />
                                  ),
                                },
                                {
                                  title: "",
                                  accessor: "",
                                  render: (header, headerIndex) => (
                                    <ActionIcon
                                      onClick={() => {
                                        if (!confirm("Are you sure?")) return;
                                        setDataRetrievers([
                                          ...dataRetrievers.slice(0, index),
                                          {
                                            ...dataRetriever,
                                            headers: [
                                              ...dataRetriever.headers.slice(
                                                0,
                                                headerIndex,
                                              ),
                                              ...dataRetriever.headers.slice(
                                                headerIndex + 1,
                                              ),
                                            ],
                                          },
                                          ...dataRetrievers.slice(index + 1),
                                        ]);
                                      }}
                                    >
                                      <IconTrash />
                                    </ActionIcon>
                                  ),
                                },
                              ]}
                            />
                          </>
                        ),
                      },
                      {
                        title: "Mappings",
                        accessor: "",
                        render: (dataRetriever, index) => (
                          <>
                            <Button
                              onClick={() => {
                                setDataRetrievers([
                                  ...dataRetrievers.slice(0, index),
                                  {
                                    ...dataRetriever,
                                    mappingConfiguration: {
                                      ...dataRetriever.mappingConfiguration,
                                      mappings: [
                                        ...dataRetriever.mappingConfiguration
                                          .mappings,
                                        {
                                          path: "",
                                          mappedTo: "",
                                        },
                                      ],
                                    },
                                  },
                                  ...dataRetrievers.slice(index + 1),
                                ]);
                              }}
                            >
                              Add mapping
                            </Button>
                            <DataTable
                              columns={[
                                {
                                  title: "Path",
                                  accessor: "",
                                  render: (mapping, mappingIndex) => (
                                    <input
                                      placeholder="ex: users[0].name"
                                      value={mapping.path}
                                      onChange={(e) => {
                                        const path = e.target.value;
                                        setDataRetrievers([
                                          ...dataRetrievers.slice(0, index),
                                          {
                                            ...dataRetriever,
                                            mappingConfiguration: {
                                              ...dataRetriever.mappingConfiguration,
                                              mappings: [
                                                ...dataRetriever.mappingConfiguration.mappings.slice(
                                                  0,
                                                  mappingIndex,
                                                ),
                                                {
                                                  ...mapping,
                                                  path,
                                                },
                                                ...dataRetriever.mappingConfiguration.mappings.slice(
                                                  mappingIndex + 1,
                                                ),
                                              ],
                                            },
                                          },
                                          ...dataRetrievers.slice(index + 1),
                                        ]);
                                      }}
                                    />
                                  ),
                                },
                                {
                                  title: "Mapped to",
                                  accessor: "",
                                  render: (mapping, mappingIndex) => (
                                    <input
                                      placeholder="ex: name"
                                      value={mapping.mappedTo}
                                      onChange={(e) => {
                                        const mappedTo = e.target.value;
                                        setDataRetrievers([
                                          ...dataRetrievers.slice(0, index),
                                          {
                                            ...dataRetriever,
                                            mappingConfiguration: {
                                              ...dataRetriever.mappingConfiguration,
                                              mappings: [
                                                ...dataRetriever.mappingConfiguration.mappings.slice(
                                                  0,
                                                  mappingIndex,
                                                ),
                                                {
                                                  ...mapping,
                                                  mappedTo,
                                                },
                                                ...dataRetriever.mappingConfiguration.mappings.slice(
                                                  mappingIndex + 1,
                                                ),
                                              ],
                                            },
                                          },
                                          ...dataRetrievers.slice(index + 1),
                                        ]);
                                      }}
                                    />
                                  ),
                                },
                                {
                                  title: "",
                                  accessor: "",
                                  render: (mapping, mappingIndex) => (
                                    <ActionIcon
                                      onClick={() => {
                                        if (!confirm("Are you sure?")) return;
                                        setDataRetrievers([
                                          ...dataRetrievers.slice(0, index),
                                          {
                                            ...dataRetriever,
                                            mappingConfiguration: {
                                              ...dataRetriever.mappingConfiguration,
                                              mappings: [
                                                ...dataRetriever.mappingConfiguration.mappings.slice(
                                                  0,
                                                  mappingIndex,
                                                ),
                                                ...dataRetriever.mappingConfiguration.mappings.slice(
                                                  mappingIndex + 1,
                                                ),
                                              ],
                                            },
                                          },
                                          ...dataRetrievers.slice(index + 1),
                                        ]);
                                      }}
                                    >
                                      <IconTrash />
                                    </ActionIcon>
                                  ),
                                },
                              ]}
                              records={
                                dataRetriever.mappingConfiguration.mappings
                              }
                            />
                          </>
                        ),
                      },
                      {
                        title: "",
                        accessor: "",
                        render: (dataRetriever, index) => (
                          <ActionIcon
                            onClick={() => {
                              if (!confirm("Are you sure?")) return;
                              setDataRetrievers([
                                ...dataRetrievers.slice(0, index),
                                ...dataRetrievers.slice(index + 1),
                              ]);
                            }}
                          >
                            <IconTrash />
                          </ActionIcon>
                        ),
                      },
                    ]}
                    records={dataRetrievers}
                  />
                </>
              );
            },
          },
        }) ||
          {}),
      }}
      onSubmit={async (data) => {
        const { name, description, allowLogin, ...configuration } = data;
        if (idpId) {
          await updateIdentityProvider({
            variables: {
              id: idpId,
              name,
              description,
              allowLogin,
              configuration: {
                ...configuration,
                dataRetrievers: configuration?.dataRetrievers ?? [],
                providerType: "OAUTH2",
              },
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
          });
        } else {
          await createIdentityProvider({
            variables: {
              name,
              description,
              allowLogin,
              parentIdentityProviderId:
                selectedIdp.id === "custom" ? undefined : selectedIdp.id,
              configuration: {
                ...configuration,
                dataRetrievers: configuration?.dataRetrievers ?? [],
                providerType: "OAUTH2",
              },
            },
          }).then((data) => {
            if (data?.errors && data.errors.length > 0) {
              throw new Error(data.errors[0].message);
            }
          });
        }
        navigate(-1);
      }}
    />
  );
};

export const IdentityProviders = () => {
  const navigate = useNavigate();
  const clipboard = useClipboard();
  const { loading, data, refetch } = useQuery<{
    identityProviders: OrganizationIdentityProvider[];
  }>(gql`
    query {
      identityProviders {
        id
        name
        allowLogin
      }
    }
  `);
  const [deleteIdentityProvider] = useMutation(gql`
    mutation ($id: ID!) {
      deleteIdentityProvider(id: $id)
    }
  `);

  const deleteIdp = async (idpId: string) => {
    if (confirm("Are you sure you want to delete this identity provider?")) {
      await deleteIdentityProvider({ variables: { id: idpId } });
      await refetch();
    }
  };

  return (
    <>
      <Button onClick={() => navigate("new")}>Add identity provider</Button>
      <DataTable
        columns={[
          {
            title: "Name",
            accessor: "name",
            render: (idp) => (
              <Anchor component={Link} to={idp.id}>
                {idp.name}
              </Anchor>
            ),
          },
          {
            title: "Allowed login",
            accessor: "allowLogin",
            render: (allowLogin) => (allowLogin.allowLogin ? "Yes" : "No"),
          },
          {
            title: "",
            accessor: "",
            render: (idp) => (
              <Group>
                <Tooltip
                  label={
                    <Text>
                      Copy Redirect URL
                      <br />
                      <Text size="xs" color="dimmed">
                        You can use this URL to configure the redirect URL of
                        your OAuth2 provider.
                        <br />
                        If set to this URL, users will be automatically
                        authenticated without having to be redirected to the
                        login page.
                      </Text>
                    </Text>
                  }
                >
                  <ActionIcon
                    onClick={() =>
                      clipboard.copy(
                        `${window.location.origin.replace(
                          "console.",
                          "idp.warrior.",
                        )}/login/${idp.id}`,
                      )
                    }
                  >
                    <IconCopy />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Copy signup URL">
                  <ActionIcon color="red" onClick={() => deleteIdp(idp.id)}>
                    <IconTrash />
                  </ActionIcon>
                </Tooltip>
              </Group>
            ),
          },
        ]}
        records={data?.identityProviders}
        fetching={loading}
      />
    </>
  );
};
