import { usePaginatedLoadAll } from "./paginated-query.hook";
import { gql } from "@apollo/client";
import { usePlayers } from "./players.hook";
import { useState } from "react";
import {
  Player,
  TournamentTeam,
  TournamentTeamMemberDto,
  TournamentTeamStatus,
} from "../gql/graphql";

export const useTeamsFromTournament = ({
  tournamentId,
  skip,
  status,
}: {
  tournamentId: string;
  skip?: boolean;
  status?: TournamentTeamStatus;
}): {
  results: (Omit<TournamentTeam, "members"> & {
    members: TournamentTeamMemberDto & { player: Player }[];
  })[];
  loading: boolean;
} => {
  const [playerIds, setPlayerIds] = useState<string[]>([]);

  const { loading, results } = usePaginatedLoadAll<any, any, TournamentTeam>(
    gql`
      query (
        $tournamentId: ID!
        $page: PageInfo!
        $status: TournamentTeamStatus
      ) {
        tournamentTeams(
          tournamentId: $tournamentId
          page: $page
          memberStatus: ACCEPTED
          status: $status
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodes {
            id
            name
            status
            members {
              status
              playerProfileId
            }
          }
        }
      }
    `,
    {
      variables: {
        tournamentId,
        status,
      },
      onResults: async (data) => {
        setPlayerIds(
          data
            .flatMap((team) => team.members)
            .map((member) => member.playerProfileId),
        );
      },
      skip,
    },
  );

  const { loading: playersLoading, results: players } = usePlayers({
    playerIds,
    skip: loading,
  });

  return {
    loading: loading || playersLoading,
    results:
      (players.length > 0 &&
        results.map(
          (team) =>
            ({
              ...team,
              members: team.members.map((member) => ({
                ...member,
                player: players.find(
                  (player) => player.id === member.playerProfileId,
                ),
              })),
            }) as any,
        )) ||
      [],
  };
};
