import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import {
  Organization,
  OrganizationIdentityProvider,
  RegisterCustomTeamsInput,
  Tournament,
  TournamentTeam,
  TournamentTeamStatus,
} from "../../../../gql/graphql";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  ActionIcon,
  Anchor,
  Button,
  Divider,
  Flex,
  Grid,
  Group,
  Loader,
  MultiSelect,
  NumberInput,
  ScrollArea,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { AutoDataTable } from "../../components/AutoDataTable";
import { modals } from "@mantine/modals";
import { isNumber } from "lodash";
import { useSelectedOrganizationId } from "../../../../business/OrganizationId";
import { IconX } from "@tabler/icons-react";
import { usePlayers } from "../../../../business/players.hook";

const TeamInfo = ({ teamId }: { teamId: string }) => {
  const [playerIds, setPlayerIds] = useState<string[]>([]);
  const { data, loading } = useQuery<{ tournamentTeam: TournamentTeam }>(
    gql`
      query ($id: ID!) {
        tournamentTeam(teamId: $id) {
          id
          customFields {
            name
            value
            property
          }
          members {
            playerProfileId
          }
        }
      }
    `,
    {
      variables: {
        id: teamId,
      },
      onCompleted: (data) => {
        setPlayerIds(
          data?.tournamentTeam.members.map(
            (member) => member.playerProfileId as string,
          ) ?? [],
        );
      },
    },
  );
  const { loading: playersLoading, results } = usePlayers({
    playerIds,
    skip: loading,
  });

  return (
    ((loading || playersLoading) && <Loader />) || (
      <ScrollArea h={100}>
        <Grid columns={2}>
          <Grid.Col span={1}>
            <Text size="lg" fw={700}>
              Custom fields
            </Text>
            {data?.tournamentTeam.customFields.map((field, idx) => (
              <div key={idx}>
                {field.name}: {field.value}
              </div>
            ))}
          </Grid.Col>
          <Grid.Col span={1}>
            <Text size="lg" fw={700}>
              Members
            </Text>
            {data?.tournamentTeam.members.map((member) => {
              const player = results.find(
                (player) => player.id === member.playerProfileId,
              );

              if (!player) {
                return null;
              }

              return (
                <div key={member.playerProfileId}>
                  <Anchor
                    component={Link}
                    to={`/players/${member.playerProfileId}`}
                  >
                    {player.username ?? member.playerProfileId}
                  </Anchor>
                  <Text ml={10} size="sm" color="grey">
                    {player.customFields.map((field, idx) => (
                      <div key={idx}>
                        {field.name}: {field.value}
                      </div>
                    ))}
                  </Text>
                  <Divider />
                  <Text ml={10} size="sm" color="grey">
                    {player.identities?.flatMap((identity) => (
                      <>
                        <Text size="sm" color="grey">
                          {identity.organizationIdentityProvider?.name}
                        </Text>
                        {identity.properties.map((field, idx) => (
                          <div key={idx}>
                            {field.property}: {field.value}
                          </div>
                        ))}
                      </>
                    )) ?? []}
                  </Text>
                </div>
              );
            })}
          </Grid.Col>
        </Grid>
      </ScrollArea>
    )
  );
};

const GenerateRandomTeamsModal = ({
  tournamentId,
}: {
  tournamentId: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [teamsCount, setTeamsCount] = useState(1);
  const [members, setMembers] = useState(1);
  const [teamStatus, setTeamStatus] = useState<TournamentTeamStatus>(
    TournamentTeamStatus.Registered,
  );
  const [registerCustomTournamentTeams] = useMutation<
    {
      registerCustomTournamentTeams: { id: string };
    },
    { tournamentId: string; input: RegisterCustomTeamsInput }
  >(gql`
    mutation ($tournamentId: ID!, $input: RegisterCustomTeamsInput!) {
      registerCustomTournamentTeams(
        tournamentId: $tournamentId
        input: $input
      ) {
        id
      }
    }
  `);
  return (
    <Stack>
      <NumberInput
        label={"Teams count"}
        min={1}
        max={100}
        value={teamsCount}
        onChange={(value) => setTeamsCount(isNumber(value) ? value : 1)}
      />
      <NumberInput
        label={"Team members"}
        min={1}
        max={5}
        value={members}
        onChange={(value) => setMembers(isNumber(value) ? value : 1)}
      />
      <Select
        data={Object.values(TournamentTeamStatus)}
        value={teamStatus}
        onChange={(value) => setTeamStatus(value as TournamentTeamStatus)}
      />
      <Button
        loading={loading}
        onClick={() => {
          setLoading(true);
          registerCustomTournamentTeams({
            variables: {
              tournamentId,
              input: {
                customTeams: Array.from({ length: teamsCount }).map(
                  (_, idx) => ({
                    name: `Team ${idx + 1}`,
                    tag: `T${idx + 1}`,
                    customFields: [],
                    status: teamStatus,
                    members: Array.from({ length: members }).map((_, idx) => ({
                      player: {
                        customFields: [],
                        description: "Generated player",
                        username: `Team ${idx + 1} - ${idx + 1}`,
                      },
                    })),
                  }),
                ),
              },
            },
          })
            .then((data) => {
              if (data?.errors && data.errors.length > 0) {
                throw new Error(data.errors[0].message);
              }
              modals.closeAll();
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        Generate
      </Button>
    </Stack>
  );
};

export const TournamentTeams = () => {
  const tournamentId = useParams().tournamentId;
  const organizationId = useSelectedOrganizationId();
  const { data, loading } = useQuery<{
    tournament: Tournament;
    organization: Organization;
    identityProviders: OrganizationIdentityProvider[];
  }>(
    gql`
      query ($id: ID!, $organizationId: ID!) {
        organization(id: $organizationId) {
          configuration {
            customFields {
              name
              property
            }
          }
        }
        tournament(id: $id) {
          configuration {
            customFields {
              name
              property
            }
          }
        }
        identityProviders {
          id
          name
          configuration {
            ... on OAuthClientConfiguration {
              dataRetrievers {
                mappingConfiguration {
                  mappings {
                    mappedTo
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        id: tournamentId,
        organizationId,
      },
    },
  );
  const [filters, setFilters] = useState<{
    filterByStatus?: TournamentTeamStatus;
    filterByCustomFields?: { property: string; value: string }[];
    filterByPlayerUsername?: string;
    filterByPlayerCustomFields?: { property: string; value: string }[];
    filterByPlayerIdentityProviderCustomFields?: {
      identityProvider: string;
      property: string;
      value: string;
    }[];
  }>();
  const [appliedFilters, setAppliedFilters] = useState<{
    filterByStatus?: TournamentTeamStatus;
    filterByCustomFields?: { property: string; value: string }[];
    filterByPlayerUsername?: string;
    filterByPlayerCustomFields?: { property: string; value: string }[];
    filterByPlayerIdentityProviderCustomFields?: {
      identityProvider: string;
      property: string;
      value: string;
    }[];
  }>();

  const [updateTeamStatus] = useMutation(gql`
    mutation ($id: ID!, $status: TournamentTeamStatus!) {
      updateTournamentTeamStatus(tournamentTeamId: $id, status: $status) {
        id
      }
    }
  `);

  if (loading) {
    return <Loader />;
  }

  const playerPropertiesFilter =
    appliedFilters?.filterByPlayerCustomFields?.filter(
      (f) => !f.property.includes(":"),
    );
  const playerIdentityProviderPropertiesFilter =
    appliedFilters?.filterByPlayerCustomFields
      ?.filter((field) => field.property.includes(":"))
      .map((field) => {
        const [identityProviderId, property] = field.property.split(":");
        return {
          identityProviderId: identityProviderId,
          property,
          value: field.value,
        };
      });
  return (
    <>
      <Title>Teams</Title>
      <Button
        onClick={() => {
          modals.open({
            title: "Teams count to generate",
            centered: true,
            onClose: () => {
              window.location.reload();
            },
            children: (
              <GenerateRandomTeamsModal tournamentId={tournamentId as string} />
            ),
          });
        }}
      >
        Generate random teams
      </Button>
      <Flex gap={10}>
        <Select
          label="Filter by status"
          onChange={(value) => {
            setFilters({
              ...filters,
              filterByStatus:
                value === "" ? undefined : (value as TournamentTeamStatus),
            });
          }}
          data={Object.values(TournamentTeamStatus).concat(
            "" as TournamentTeamStatus,
          )}
        />
        <TextInput
          label="Filter by player username"
          onChange={(event) => {
            setFilters({
              ...filters,
              filterByPlayerUsername:
                event.currentTarget.value.length > 0
                  ? event.currentTarget.value
                  : undefined,
            });
          }}
        />
      </Flex>
      <Flex gap={10}>
        <Select
          label="Filter by team custom field"
          onChange={(value) => {
            setFilters({
              ...filters,
              filterByCustomFields:
                value === ""
                  ? undefined
                  : (filters?.filterByCustomFields || [])
                      .filter((f) => f.property !== value)
                      .concat([{ property: value as string, value: "" }]),
            });
          }}
          data={
            data?.tournament?.configuration?.customFields?.map((field) => ({
              label: field.name,
              value: field.property,
            })) ?? []
          }
        />
      </Flex>
      <Flex gap={10}>
        {filters?.filterByCustomFields?.map((field, idx) => (
          <TextInput
            key={idx}
            label={
              <Group spacing={0}>
                {field.property}
                <ActionIcon
                  color={"red"}
                  onClick={() => {
                    const newFilteredFields =
                      filters.filterByCustomFields?.filter(
                        (f) => f.property !== field.property,
                      );
                    setFilters({
                      ...filters,
                      filterByCustomFields:
                        newFilteredFields && newFilteredFields.length > 0
                          ? newFilteredFields
                          : undefined,
                    });
                  }}
                  size={"xs"}
                >
                  <IconX />
                </ActionIcon>
              </Group>
            }
            onChange={(event) => {
              setFilters({
                ...filters,
                filterByCustomFields: filters.filterByCustomFields?.map((f) =>
                  f.property === field.property
                    ? {
                        property: f.property,
                        value: event.currentTarget.value,
                      }
                    : f,
                ),
              });
            }}
          />
        ))}
      </Flex>
      <Flex gap={10}>
        <Select
          label="Filter by player custom field"
          onChange={(value) => {
            setFilters({
              ...filters,
              filterByPlayerCustomFields:
                value === ""
                  ? undefined
                  : (filters?.filterByPlayerCustomFields ?? [])
                      .filter((f) => f.property !== value)
                      .concat([{ property: value as string, value: "" }]),
            });
          }}
          data={(
            data?.organization?.configuration?.customFields?.map((field) => ({
              label: field.name,
              value: field.property,
            })) ?? []
          ).concat(
            data?.identityProviders.flatMap(
              (provider) =>
                provider.configuration?.dataRetrievers
                  ?.flatMap(
                    (retriever) =>
                      retriever.mappingConfiguration?.mappings.map(
                        (mapping) => ({
                          label: `${provider.name}->${mapping.mappedTo}`,
                          value: `${provider.id}:${mapping.mappedTo}`,
                        }),
                      ),
                  )
                  .filter((m) => m) ?? [],
            ) ?? [],
          )}
        />
      </Flex>
      <Flex gap={10}>
        {filters?.filterByPlayerCustomFields?.map((field, idx) => (
          <TextInput
            key={idx}
            label={
              <Group spacing={0}>
                {field.property.includes(":")
                  ? `${data?.identityProviders?.find(
                      (idp) => idp.id === field.property.split(":")[0],
                    )?.name}->${field.property.split(":")[1]}`
                  : field.property}{" "}
                <ActionIcon
                  color={"red"}
                  onClick={() => {
                    const newFilteredFields =
                      filters.filterByPlayerCustomFields?.filter(
                        (f) => f.property !== field.property,
                      );
                    setFilters({
                      ...filters,
                      filterByPlayerCustomFields:
                        newFilteredFields && newFilteredFields.length > 0
                          ? newFilteredFields
                          : undefined,
                    });
                  }}
                  size={"xs"}
                >
                  <IconX />
                </ActionIcon>
              </Group>
            }
            onChange={(event) => {
              setFilters({
                ...filters,
                filterByPlayerCustomFields:
                  filters.filterByPlayerCustomFields?.map((f) =>
                    f.property === field.property
                      ? {
                          property: f.property,
                          value: event.currentTarget.value,
                        }
                      : f,
                  ),
              });
            }}
          />
        ))}
      </Flex>
      <Flex>
        <Button onClick={() => setAppliedFilters(filters)}>
          Apply Filters
        </Button>
      </Flex>
      <AutoDataTable
        highlightOnHover
        rowExpansion={(team) => <TeamInfo teamId={team.id} />}
        queryVariables={{
          tournamentId,
          status: appliedFilters?.filterByStatus,
          propertiesFilter: appliedFilters?.filterByCustomFields,
          playerUsernameQuery: appliedFilters?.filterByPlayerUsername,
          playerPropertiesFilter: !!playerPropertiesFilter?.length
            ? playerPropertiesFilter
            : undefined,
          playerIdentityProviderPropertiesFilter:
            !!playerIdentityProviderPropertiesFilter?.length
              ? playerIdentityProviderPropertiesFilter
              : undefined,
        }}
        query={gql`
          query (
            $cursor: String
            $count: Float
            $tournamentId: ID!
            $status: TournamentTeamStatus
            $propertiesFilter: [PropertyValueInput!]
            $playerUsernameQuery: String
            $playerPropertiesFilter: [PropertyValueInput!]
          ) {
            tournamentTeams(
              page: { first: $count, after: $cursor }
              status: $status
              tournamentId: $tournamentId
              propertiesFilter: $propertiesFilter
              playerUsernameQuery: $playerUsernameQuery
              playerPropertiesFilter: $playerPropertiesFilter
            ) {
              pageInfo {
                hasNextPage
                endCursor
              }
              nodes {
                id
                name
                status
              }
              totalCount
            }
          }
        `}
        columns={[
          {
            accessor: "name",
            title: "Name",
          },
          {
            accessor: "",
            title: "Status",
            rawElement: (item: TournamentTeam) => (
              <Select
                defaultValue={item.status}
                onChange={(selected) => {
                  // TODO Lock field
                  updateTeamStatus({
                    variables: {
                      id: item.id,
                      status: selected as TournamentTeamStatus,
                    },
                  })
                    .then(/* TODO */)
                    .finally(/* TODO UNLOCK FIELD */);
                }}
                data={Object.values(TournamentTeamStatus)}
              />
            ),
          },
        ]}
      />
    </>
  );
};
